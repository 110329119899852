import React from 'react';
import './app.css';
import { QueryExample } from '../query-example/query-example'




function App() {
  return (
    <div className="App">
      <QueryExample />
      Hello There
    </div>
  );
}

export default App;
